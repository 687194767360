import {UserFragmentData} from '@app-features/users/types';
import {createAction} from 'redux-starter-kit';


export interface LogoutCleanupPayload {
  local: boolean;
}

export type AuthActions =
  | ReturnType<typeof logout>
  | ReturnType<typeof logoutCleanup>
  | ReturnType<typeof storeUser>

export const logout = createAction<void, "auth/logout">("auth/logout");
export const logoutCleanup = createAction<LogoutCleanupPayload, "auth/logoutCleanup">("auth/logoutCleanup");
export const storeUser = createAction<UserFragmentData, "auth/storeUser">("auth/storeUser");
